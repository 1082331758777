import { cardBody } from '../_app.p.tournaments/tournament-card/tournament-card.css';
import { LoginEmailForm } from '../waitlist/waitlist-card/landing-email-form';
import { cardHeading } from '../waitlist/waitlist-card/waitlist-card.css';
import { waitlistCardContent } from './route.css';

export default function Join() {
  return (
    <div className={waitlistCardContent}>
      <h1 className={cardHeading}>Become a member</h1>
      <p className={cardBody}>
        Sign up in seconds and be part of a growing community
      </p>

      <LoginEmailForm />
    </div>
  );
}
